import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { getProperty } from "dot-prop";
import { Link } from "react-router-dom";
import { convertPrice, muskContinuesValue } from "../../utils";

const EVENTS_MAPPING: any = {
  order_created: {
    title: "Call Received",
    description: (payload: any) => (
      <div>
        <Text fontSize="xs" color="blue">
          +91 {payload.mobile}
        </Text>
        <Text fontSize="xs" color="gray.500">
          Source: {getProperty(payload, "originSource.source")}
        </Text>
      </div>
    ),
    icon: "mdi:phone-ring",
  },
  order_update: {
    title: "Order Update",
    description: () => <div>Order updated</div>,
    icon: "ic:baseline-update",
  },
  order_cancelled: {
    title: "Order cancelled",
    description: () => <div>Order cancelled</div>,
    icon: "dashicons:no-alt",
  },
  pickup_address_updated: {
    title: "Pickup Address Update",
    description: (payload: any) => <div>{constructAddress(getProperty(payload, "waypoints[0].address"))}</div>,
    icon: "ic:outline-edit-location-alt",
  },
  please_assign_manually: {
    title: "Please assign manually",
    description: () => <div>Please assign manually</div>,
    icon: "mdi:account-alert-outline",
  },
  partner_dispatch_triggered: {
    title: "Partner Dispatch Triggered",
    description: () => <div>Partner Dispatch Triggered</div>,
    icon: "mdi:truck-fast-outline",
  },
  dispatch_reached_pickup: {
    title: "Dispatch reached Pickup",
    description: () => <div>Dispatch reached Pickup</div>,
    icon: "mingcute:location-3-fill",
  },
  feedback_reminder_sms_sent: {
    title: "Feedback Reminder SMS Sent",
    description: () => <div>Feedback Reminder SMS Sent</div>,
    icon: "mdi:message-alert",
  },
  document_removed: {
    title: "Document Removed",
    description: () => <div>Document Removed</div>,
    icon: "mdi:file-remove",
  },
  document_uploaded: {
    title: "Document Uploaded",
    description: () => <div>Document Uploaded</div>,
    icon: "mdi:file-document",
  },
  location_message_triggered: {
    title: "Send Location message",
    description: (payload: any) => (
      <div>
        <Text fontSize="xs" color="gray.500">
          Send SMS to +91 {getProperty(payload, "number")}
        </Text>
      </div>
    ),
    icon: "line-md:my-location-loop",
  },
  payment_link_message_triggered: {
    title: "Payment Link sent",
    description: () => (
      <div>
        <Text fontSize="xs" color="gray.500">
          Payment link sent
        </Text>
      </div>
    ),
    icon: "mdi:link-variant",
  },
  dispatch_triggered: {
    title: "Dispatch Triggered",
    description: (payload: any) => (
      <div>
        <Text fontSize="xs" color="gray.500">
          Assignment type: {getProperty(payload, "assignmentType")}
        </Text>
        <Text fontSize="xs" color="gray.500">
          dispatch type: {getProperty(payload, "dispatchType")}
        </Text>
        {getProperty(payload, "entityRequired[0]") && (
          <Box py="2">
            <Text>{getEntityType(getProperty(payload, "entityRequired[0]"))}</Text>
          </Box>
        )}
      </div>
    ),
    icon: "mdi:truck-fast-outline",
  },
  dispatch_scheduled: {
    title: "Dispatch scheduled",
    description: () => (
      <div>
        <Text fontSize="xs" color="gray.500">
          Dispatch scheduled
        </Text>
      </div>
    ),
    icon: "uim:calender",
  },
  dispatch_reassigned: {
    title: "Dispatch Reassigned",
    description: () => (
      <div>
        <Text fontSize="xs" color="gray.500">
          Dispatch Reassigned
        </Text>
      </div>
    ),
    icon: "ic:outline-swap-horiz",
  },
  dispatch_initiated: {
    title: "Dispatch Initiated",
    description: (payload: any) => {
      return (
        <div>
          <Flex align="center">
            <Text fontSize="xs" color="gray.500">
              Type:
            </Text>
            <Text fontWeight="bold" fontSize="ks">
              {getProperty(payload, "event")}
            </Text>
          </Flex>
          <Text fontSize="xs" color="gray.500">
            Assignment type: {getProperty(payload, "assignmentType")}
          </Text>
          <Text fontSize="xs" color="gray.500">
            dispatch ID: {muskContinuesValue(getProperty(payload, "dispatchId"), 28)}
          </Text>
        </div>
      );
    },
    icon: "mdi:truck-fast-outline",
  },
  dispatch_sourcing: {
    title: "Dispatch sourcing",
    description: (payload: any) => (
      <div>
        <Flex align="center">
          <Text fontSize="xs" color="gray.500">
            Type:
          </Text>
          <Text fontWeight="bold" fontSize="ks">
            {getProperty(payload, "event")}
          </Text>
        </Flex>
        <Text fontSize="xs" color="gray.500">
          Assignment type: {getProperty(payload, "assignmentType")}
        </Text>
        <Text fontSize="xs" color="gray.500">
          dispatch ID: {muskContinuesValue(getProperty(payload, "dispatchId"), 28)}
        </Text>
      </div>
    ),
    icon: "mdi:source-branch",
  },
  dispatch_filtering: {
    title: "Dispatch Filtering",
    description: (payload: any) => (
      <div>
        <Flex align="center">
          <Text fontSize="xs" color="gray.500">
            Type:
          </Text>
          <Text fontWeight="bold" fontSize="ks">
            {getProperty(payload, "event")}
          </Text>
        </Flex>
        <Text fontSize="xs" color="gray.500">
          Assignment type: {getProperty(payload, "assignmentType")}
        </Text>
        <Text fontSize="xs" color="gray.500">
          dispatch ID: {muskContinuesValue(getProperty(payload, "dispatchId"), 28)}
        </Text>
      </div>
    ),
    icon: "mdi:filter-variant",
  },
  dispatch_sorting: {
    title: "Dispatch Sorting",
    description: (payload: any) => (
      <div>
        <Flex align="center">
          <Text fontSize="xs" color="gray.500">
            Type:
          </Text>
          <Text fontWeight="bold" fontSize="ks">
            {getProperty(payload, "event")}
          </Text>
        </Flex>
        <Text fontSize="xs" color="gray.500">
          Assignment type: {getProperty(payload, "assignmentType")}
        </Text>
        <Text fontSize="xs" color="gray.500">
          dispatch ID: {muskContinuesValue(getProperty(payload, "dispatchId"), 28)}
        </Text>
      </div>
    ),
    icon: "mdi:sort",
  },
  requesting_pilot: {
    title: "Dispatch Pilot",
    description: (payload: any) => (
      <div>
        <Flex align="center">
          <Text fontSize="xs" color="gray.500">
            Type:
          </Text>
          <Text fontWeight="bold" fontSize="ks">
            {getProperty(payload, "event")}
          </Text>
        </Flex>
        <Text fontSize="xs" color="gray.500">
          Assignment type: {getProperty(payload, "assignmentType")}
        </Text>
        <Text fontSize="xs" color="gray.500">
          dispatch ID: {muskContinuesValue(getProperty(payload, "dispatchId"), 28)}
        </Text>
      </div>
    ),
    icon: "mdi:airplane-takeoff",
  },
  tracking_link_message_triggered: {
    title: "Send Tracking Link SMS",
    description: (payload: any) => (
      <div>
        <Flex>
          <Text fontSize="xs" color="gray.500">
            You can track the assigned vehicle by clicking on this{" "}
            <Link to={getProperty(payload, "link") || ""} target="_blank">
              <Text color={"blue"} fontWeight={"semibold"}>
                Link
              </Text>
            </Link>
          </Text>
        </Flex>
      </div>
    ),
    icon: "mdi:message-text-outline",
  },
  dispatch_assignment: {
    title: "Dispatch Assignment",
    description: (payload: any) => (
      <div>
        <Flex align="center">
          <Text fontSize="xs" color="gray.500">
            Type:
          </Text>
          <Text fontWeight="bold" fontSize="ks">
            {getProperty(payload, "event")}
          </Text>
        </Flex>
        <Text fontSize="xs" color="gray.500">
          Assignment type: {getProperty(payload, "assignmentType")}
        </Text>
        <Text fontSize="xs" color="gray.500">
          dispatch ID: {muskContinuesValue(getProperty(payload, "dispatchId"), 28)}
        </Text>
      </div>
    ),
    icon: "mdi:clipboard-check-outline",
  },
  ambulance_accepted_trip: {
    title: "Ambulance Accepted Trip",
    description: () => <div>Ambulance Accepted Trip</div>,
    icon: "mdi:ambulance",
  },
  ambulance_on_trip: {
    title: "Ambulance on trip",
    description: () => <div>Ambulance on trip</div>,
    icon: "mdi:ambulance",
  },
  ambulance_rejected_trip: {
    title: "Ambulance Rejected Trip",
    description: () => <div>Ambulance Rejected Trip</div>,
    icon: "mdi:ambulance",
  },
  patient_onboard: {
    title: "Patient Onboard",
    description: () => <div>Patient Onboard</div>,
    icon: "mdi:bed",
  },
  manual_dispatch_triggered: {
    title: "Manual Dispatch Triggered",
    description: () => <div>Manual Dispatch Triggered</div>,
    icon: "mdi:truck-fast-outline",
  },
  order_recreated: {
    title: "Order Recreated",
    description: () => <div>Order Recreated</div>,
    icon: "mdi:restore",
  },
  patient_dropped: {
    title: "Patient Dropped",
    description: () => <div>Patient Dropped</div>,
    icon: "mdi:bed-empty",
  },
  payment_done: {
    title: "Payment Done",
    description: () => <div>Payment Done</div>,
    icon: "mdi:cash-multiple",
  },
  feedback_sms_sent: {
    title: "Feedback SMS Sent",
    description: () => <div>Feedback SMS Sent</div>,
    icon: "mdi:message-text",
  },
  feedback_received: {
    title: "Feedback Received",
    description: () => <div>Feedback Received</div>,
    icon: "mdi:comment-alert",
  },
  dispatch_fulfilled: {
    title: "Dispatch Fulfilled",
    description: () => <div>Dispatch Fulfilled</div>,
    icon: "mdi:check-circle",
  },
  dispatch_updated: {
    title: "Dispatch Updated",
    description: () => (
      <div>
        <Text fontSize="xs" color="gray.500">
          Dispatch updated
        </Text>
      </div>
    ),
    icon: "mdi:update",
  },
  payment_success: {
    title: "Payment success",
    description: ({ data }: any) => (
      <Stack>
        <Flex>
          <Text fontSize="xs" color="gray.500">
            Payment successful of
          </Text>
          <Box ml="2">
            <Text fontWeight="bold" fontSize="xs" color="blue">
              ₹{convertPrice(data?.amount || data?.transactionAmount)}
            </Text>
          </Box>
        </Flex>
        <Text fontSize="xs" color="gray.500">
          Payment Mode: <span style={{ fontWeight: 800, color: "black" }}>{data?.paymentMode}</span>
        </Text>
      </Stack>
    ),
    icon: "mdi:cash-check",
  },
  dispatch_unfulfilled: {
    title: "Dispatch Failed",
    description: (payload: any) => (
      <Box>
        <Text fontSize="xs" color="gray.500">
          Dispatch Failed, Please assign it manually.
        </Text>
        <Box ml="0" onClick={() => (window.location.href = `/create-case/${payload?.orderId}/manual-dispatch`)}>
          <Text fontWeight="bold" fontSize="xs" color="blue">
            Manual Dispatch
          </Text>
        </Box>
      </Box>
    ),
    icon: "mdi:account-cancel-outline",
  },
  auto_dispatch_failed: {
    title: "Please assign manually",
    description: (payload: any) => (
      <Box>
        <Text fontSize="xs" color="gray.500">
          Please assign it manually.
        </Text>
        <Box ml="0" onClick={() => (window.location.href = `/create-case/${payload?.orderId}/manual-dispatch`)}>
          <Text fontWeight="bold" fontSize="xs" color="blue">
            Manual Dispatch
          </Text>
        </Box>
      </Box>
    ),
    icon: "mdi:account-alert-outline",
  },
  case_payments_completed: {
    title: "Cash Payment received.",
    description: ({ data }: any) => (
      <Flex align="center">
        <Text fontSize="xs" color="gray.500">
          Cash payment complete of
        </Text>
        <Box ml="2">
          <Text fontSize="xs" color="blue" fontWeight="bold">
            ₹{convertPrice(data?.amount || data?.amountReceived)}
          </Text>
        </Box>
      </Flex>
    ),
    icon: "mdi:cash-100",
  },
};

export default EVENTS_MAPPING;

export function getDefaultEvent(eventName: any) {
  return {
    title: muskContinuesValue(getProperty(eventName.split("_").join(" ").toString(), "dispatchId"), 28),
    description: () => <div>{eventName.split("_").join(" ")}</div>,
    icon: "ph:taxi-bold",
  };
}

const getEntityType = (entity: any) => {
  return (
    <>
      <Text fontSize="xs" color="blue">
        {entity?.type}:{entity?.subtype} x {entity?.quantity}
      </Text>
    </>
  );
};

const constructAddress = (address: any) => {
  const { pincode, city, location, state, type } = address;

  return (
    <>
      <Text fontSize="xs" color="gray.500">
        type: {type}
      </Text>
      <Text fontSize="xs" color="gray.500">
        {location.split(" - ")[0] || ""}, {city}, {state}, {pincode}
      </Text>
    </>
  );
};
