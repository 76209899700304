import { useParams } from "react-router-dom";
import { useEventApi } from "../../hooks/api/useEventApi";
import { Box, Flex, Text, Step, StepDescription, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, useSteps, Heading, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import EVENTS_MAPPING, { getDefaultEvent } from "./EventMapping";
import { muskContinuesValue } from "../../utils";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import { useAppStore } from "../../store/AppStore";
import { usePubNubHook } from "../../hooks/action/usePubNub";
import NetworkWrapper from "@components/wrapper/NetworkWrapper";
import { liveEventViewDetails } from "@config/event";

const LiveEvents = () => {
  const [salt, setSalt] = useState("");
  const { id: orderId } = useParams();

  const { data: eventData, ...eventResp }: any = useEventApi({
    orderId: orderId || "",
    salt,
  });
  const [processEvents, setProcessEvents] = useState([]);

  const { loginUser }: any = useAppStore();

  const pusher = usePubNubHook(loginUser?.email || "");

  useEffect(() => {
    if (eventData?.data?.data && eventData?.data?.data?.length > 0) {
      doProcessEventData(eventData?.data?.data?.sort((a: any, b: any) => a?.timestamp - b?.timestamp));
    }
  }, [eventData?.data?.data]);

  const handleEvent = (data: any) => {
    console.log("get event: ", data);
    const randomSalt = `${Math.random() * 300}-salt-${Date.now()}`;
    setSalt(randomSalt);
  };

  useEffect(() => {
    pusher.subscribe([orderId], handleEvent);
  }, [pusher]);

  useEffect(() => {
    liveEventViewDetails({
      orderId: orderId,
    });
  }, []);

  const doProcessEventData = (events: any) => {
    const items = events.map((event: any) => {
      const data = Object.keys(EVENTS_MAPPING)?.includes(event.eventName.toLocaleLowerCase()) ? EVENTS_MAPPING[event.eventName.toLocaleLowerCase()] : getDefaultEvent(event.eventName);
      return {
        isVisible: event.timeline,
        title: (
          <Box minW="280" w={"80vw"} mb="0.5">
            <Flex justify="space-between" align="center" w={"full"}>
              <Heading textTransform={"capitalize"} fontWeight="semibold" fontSize="xs" mr={2}>
                {muskContinuesValue(data?.title, 50).toString().toLowerCase()}
              </Heading>
              <Text fontSize="2xs">{dayjs.unix(event.timestamp).format("D MMM hh:mm A")}</Text>
            </Flex>
          </Box>
        ),
        description: data?.description(event?.payload),
        icon: (
          <Box>
            <Icon icon={data?.icon || ""} fontSize={14} />
          </Box>
        ),
      };
    });

    items.push({
      title: (
        <Box w="full">
          <Flex justify="space-between" align="center">
            <Text fontWeight="semibold" fontSize="xs">
              Fetching latest status
            </Text>
            <Text color="gray-9" fontSize="xs">
              &nbsp;
            </Text>
          </Flex>
        </Box>
      ),
      isVisible: true,
      description: <div></div>,
      icon: (
        <Box>
          <Icon icon="eos-icons:loading" fontSize="12" />
        </Box>
      ),
    });

    setProcessEvents(items);
  };

  return (
    <NetworkWrapper status={eventResp}>
      <Box>
        <Stepper index={processEvents.length - 1} orientation="vertical" colorScheme="primary" gap={0} size={"sm"} w={"full"}>
          {processEvents?.map((step: any, index) => (
            <Step key={index}>
              <StepIndicator justifyContent={"center"} alignItems={"center"}>
                <StepStatus complete={step.icon} incomplete={step.icon} active={step.icon} />
              </StepIndicator>

              <Box pb={5} pt={0.5} ml={2}>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Box>
    </NetworkWrapper>
  );
};

export default LiveEvents;
