import { getEventsForCase } from "../../api/service/events";
import { useQuery } from "react-query";

const useEventApi = ({ orderId, salt = "salt", onSuccess, refetchOnWindowFocus = true }: { orderId: string; salt?: any; onSuccess?: (data: any) => void; refetchOnWindowFocus?: boolean }) => {
  return useQuery([`events-${salt}-${orderId}`], () => getEventsForCase(orderId), {
    enabled: !!orderId,
    onSuccess: (data: any) => {
      onSuccess && onSuccess(data);
    },
    refetchOnWindowFocus,
  });
};

export { useEventApi };
